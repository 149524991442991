/* Product Page */
#view_full_size {
    display: inline-block;

    .fmm_sticker_base_span {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
    }
}

/* Product List */
.products-list__articles .fmm_sticker_base_span,
#product_list .fmm_sticker_base_span {
    background: none !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.card__header__product__images {
    position: relative;
}